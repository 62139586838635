export const environment = {
    baseUrl: 'https://tevunanode.onrender.com/tevunaApi/', //basic plan
    auth: {
        domain: 'login.tevunai.com',
        clientId: 'KFAlwykpvnXwUnA6ZVRENUId2W7Djqn8',
        authorizationParams: {
            redirect_uri: window.location.origin,
            audience: 'backend'
          }
    },
    environment: 'production',
    cardKnoxApiEndpoint: 'https://api.cardknox.com/threeds/v1/jwt/production'
};