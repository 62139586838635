import { Injectable, inject } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Observable, map, switchMap, of } from 'rxjs';

interface DecodedToken {
  permissions?: string[];
  [key: string]: unknown;
}

@Injectable({
  providedIn: 'root'
})
export class MyAuthService {
  auth = inject(AuthService);

  constructor() { }

  getPermissions(): Observable<string[]> {
    return this.auth.getAccessTokenSilently().pipe(
      switchMap(token => {
        const decodedToken = this.decodeToken(token);
        return of(decodedToken?.permissions || []);
      })
    );
  }

  isAdmin(): Observable<boolean> {
    return this.getPermissions().pipe(
      map(permissions => {
        return permissions.includes('admin:everything');
      })
    );
  }

  private decodeToken(token: string): DecodedToken | null {
    try {
      return JSON.parse(atob(token.split('.')[1])) as DecodedToken;
    } catch (e) {
      return null;
    }
  }
}